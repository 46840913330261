import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { componentDestroyed, League, LeagueApi, LeaguePlayer, TeamsAndUsers, Team, UntilDestroy } from '@mtgame/core';
import { LeagueRootService } from '@core/services/league-root.service';
import { getLeaguePlayerRoute, getTeamPageRoute } from '@config/routes';
import { Router } from '@angular/router';

@UntilDestroy
@Component({
  selector: 'mtg-team-and-user-search',
  templateUrl: './team-and-user-search.component.html',
  styleUrls: ['./team-and-user-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamAndUserSearchComponent implements OnInit, OnDestroy {

  league: League;
  searchControl = new UntypedFormControl();
  teamsAndUsers: TeamsAndUsers = TeamsAndUsers.toFront({});

  constructor(
    private leagueRootService: LeagueRootService,
    private leagueApi: LeagueApi,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.leagueRootService.league$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(league => {
        this.league = league;
        this.cdr.markForCheck();
      });

    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      this.teamsAndUsers = this.searchControl.value && this.searchControl.value.length > 2
        ? await this.searchItems()
        : TeamsAndUsers.toFront({});
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
  }

  itemSelected(item: any) {
    this.searchControl.setValue('', {emitEvent: false});
    this.teamsAndUsers = TeamsAndUsers.toFront({});
    if (item instanceof Team) {
      this.router.navigate(getTeamPageRoute(item.id));
    }
    if (item instanceof LeaguePlayer) {
      this.router.navigate(getLeaguePlayerRoute(item.id));
    }
  }

  async searchItems(): Promise<TeamsAndUsers> {
    return await this.leagueApi.searchTeamsAndUsers(this.league.id, this.searchControl.value);
  }
}
