import { Injectable } from '@angular/core';
import { BaseService } from '@mtgame/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const STORE_VERSION_KEY = 'version';
const STORE_HAS_UPDATES_KEY = 'has-updates';

@Injectable({providedIn: 'root'})
export class CheckVersionService extends BaseService {
  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  get hasUpdates$(): Observable<boolean> {
    return this.store.get(STORE_HAS_UPDATES_KEY);
  }

  initialize(): void {
    this.httpClient.get('/version').subscribe(response => {
      this.store.save(STORE_VERSION_KEY, response['version']);
    });
    setInterval(() => {
      this.checkVersion();
    }, 1000 * 60 * 5);
  }

  checkVersion(): void {
    this.httpClient.get('/version').subscribe(response => {
      if (response['version'] !== this.store.value(STORE_VERSION_KEY)) {
        this.store.save(STORE_HAS_UPDATES_KEY, true);
      }
    });
  }
}
