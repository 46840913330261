import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {
  private visibleSubject = new BehaviorSubject(true);

  get visible$() {
    return this.visibleSubject;
  }

  show(animate = false): void {
    if (!this.visibleSubject.value) {
      this.visibleSubject.next(true);
    }
  }

  hide(animate = false): void {
    if (this.visibleSubject.value) {
      this.visibleSubject.next(false);
    }
  }
}
