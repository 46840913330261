import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  BaseService,
  CentrifugoService,
  Game,
  GameStatuses,
  LeagueApi,
  TournamentApi,
  TournamentSeasonApi
} from '@mtgame/core';
import { Observable } from 'rxjs';
import { EXCLUDE_GAMES_TOURNAMENT_ID, EXCLUDE_HEADLINE_GAMES } from '@utils/hardcode';
import { isPlatformBrowser } from '@angular/common';

const STORE_GAMES_KEY = 'games';

@Injectable({providedIn: 'root'})
export class GamesHeadlineService extends BaseService {
  size = 30;
  page = 1;
  total: number;
  tournamentId: number;
  leagueId: number;
  tournamentSeasonId: number;

  get games$(): Observable<Game[]> {
    return this.store.get(STORE_GAMES_KEY);
  }

  constructor(
    private leagueApi: LeagueApi,
    private tournamentApi: TournamentApi,
    private tournamentSeasonApi: TournamentSeasonApi,
    private centrifugoService: CentrifugoService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    super();
  }

  async initialize({leagueId, tournamentSeasonId, tournamentId}:
      {leagueId?: number, tournamentSeasonId?: number, tournamentId?: number}
  ): Promise<Game[]> {
    this.page = 1;
    this.leagueId = leagueId;
    this.tournamentSeasonId = tournamentSeasonId;
    this.tournamentId = tournamentId;
    this.listenCentrifugoEvents();
    return await this.loadGames();
  }

  dispose(): void {
    this.store.save(STORE_GAMES_KEY, null);
    super.dispose();
  }

  async loadGames(): Promise<Game[]> {
    let games;
    if (this.leagueId) {
      const filters = this.leagueId === 34 ?
        {statuses: [GameStatuses.open, GameStatuses.closed, GameStatuses.in_progress]} :
        {statuses: [GameStatuses.closed, GameStatuses.in_progress]};
      games = await this.leagueApi.getGames(this.leagueId, this.page, this.size, filters);
    } else if (this.tournamentId) {
      games = await this.tournamentApi.getGames(this.tournamentId, this.page, this.size,
        {statuses: [GameStatuses.closed, GameStatuses.in_progress]});
    } else if (this.tournamentSeasonId) {
      games = await this.tournamentSeasonApi.getGames(this.tournamentSeasonId, this.page, this.size,
        {statuses: [GameStatuses.closed, GameStatuses.in_progress]});
    } else {
      return Promise.resolve([]);
    }

    if (this.page === 1) {
      this.total = games.total;
      this.store.save(STORE_GAMES_KEY, games.data.filter(g => EXCLUDE_GAMES_TOURNAMENT_ID.indexOf(g.tournament.id) === -1 && EXCLUDE_HEADLINE_GAMES.indexOf(g.id) === -1));
    } else {
      this.store.appendItems(STORE_GAMES_KEY, games.data.filter(g => EXCLUDE_GAMES_TOURNAMENT_ID.indexOf(g.tournament.id) === -1 && EXCLUDE_HEADLINE_GAMES.indexOf(g.id) === -1));
    }

    return games.data;
  }

  async loadNextPage(): Promise<Game[]> {
    if (this.page * this.size >= this.total) {
      return Promise.resolve([]);
    }
    this.page++;
    return this.loadGames();
  }

  private listenCentrifugoEvents(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.centrifugoService.listen(`league_${this.leagueId}`)
     .subscribe(message => {
       switch (message.action) {
         case 'GAME_CLOSED':
         case 'GAME_UPDATED':
         case 'GAME_SCORE_UPDATED': {
           const newGame = Game.toFront(message.data);
           if ([GameStatuses.in_progress, GameStatuses.closed].indexOf(newGame.status) < 0) {
             return;
           }
           const game = (this.store.value(STORE_GAMES_KEY) || []).find(item => item.id === newGame.id);
           if (game) {
             return this.store.updateItem(STORE_GAMES_KEY, newGame);
           }
           return this.store.prependItem(STORE_GAMES_KEY, Game.toFront(message.data));
         }
       }
     });
  }
}
