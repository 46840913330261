<div class="agreement-box panel" *ngIf="league.id === 12">
  <p><strong>Политика конфиденциальности о персональных данных Хоккейной Лиги ВФСО &laquo;Трудовые резервы&raquo;</strong></p>
  <p>1. Общие положения</p>
  <p>Настоящая политика о конфиденциальности персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных посетителей сайта <a href="https://hltr.ru/">https://hltr.ru/</a>.</p>
  <p>Использование Пользователем ресурсов Сайта означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования ресурсов Сайта.</p>
  <p>Настоящая политика в отношении обработки персональных данных применяется ко всей информации, которую Оператор может получить о посетителях Сайта.</p>
  <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
  <p>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
  <p>2. Основные понятия, используемые в Политике</p>
  <p>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</p>
  <p>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных;</p>
  <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
  <p>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
  <p>Оператор &ndash; Общероссийская общественная организация &laquo;Всероссийское физкультурно-спортивное общество &laquo;Трудовые резервы&raquo;, Юридический адрес: 119019, г. Москва, Бульвар Гоголевский, д. 21, строение 1, эт.1, помещение III, комн.2ИНН 7704456650, ОГРН 1187700010190</p>
  <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
  <p>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
  <p>Политика - Политика о конфиденциальности персональных данных;</p>
  <p>Пользователь &ndash; любой посетитель Сайта;</p>
  <p>Сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://hltr.ru/">https://hltr.ru/</a></p>
  <p>3. Оператор может обрабатывать следующие персональные данные Пользователя</p>
  <ul>
    <li>Фамилия, имя, отчество;</li>
    <li>Электронный адрес;</li>
    <li>Номер телефона;</li>
    <li>Дата рождения;</li>
    <li>Адрес регистрации;</li>
    <li>Фотографическое изображение;</li>
  </ul>
  <p>Также на сайте может происходить сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;).</p>
  <p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
  <p>4. Цели обработки персональных данных</p>
  <p>Цель обработки персональных данных Пользователя &mdash;</p>
  <ul>
    <li>Идентификация Пользователя, зарегистрированного на Сайте;&nbsp;</li>
    <li>Предоставление Пользователю доступа к персонализированным ресурсам Сайта;</li>
    <li>Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя;</li>
    <li>Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем;</li>
    <li>Создание учетной записи, если Пользователь дал согласие либо самостоятельно зарегистрировал учетную запись;</li>
    <li>Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта;</li>
    <li>Осуществление рекламной деятельности с согласия Пользователя;</li>
    <li>Предоставление Пользователю уведомлений о новых продуктах и услугах, каталогов, прайсов, специальных предложений и иных сведений о различных событиях Оператора.</li>
  </ul>
  <p>Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@trurez.ru">info@trurez.ru</a> с пометкой &laquo;Отказ от уведомлений&raquo;.&nbsp;</p>
  <p>5. Правовые основания обработки персональных данных</p>
  <p>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
  <p>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
  <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
  <p>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
  <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с оказанием услуг Пользователю и с исполнением действующего законодательства.</p>
  <p>Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя/его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
  <p>Срок обработки персональных данных является неограниченным.</p>
  <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="https://hltr.ru/">https://hltr.ru/</a> с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
  <p>Уничтожение персональных данных будет произведено Оператором в срок, установленный Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
  <p>7. Заключительные положения</p>
  <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору на электронную почту <a href="mailto:info@trurez.ru">info@trurez.ru</a>.</p>
  <p>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
  <p>Пользователь соглашается с тем, что Сайт вправе передавать персональные данные соответствующего Пользователя третьим лицам, с целью выполнения своих обязательств перед Пользователем.</p>
  <p>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
  <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
  <p>Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
  <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://hltr.ru/">https://hltr.ru/</a> .</p>
</div>
<div class="agreement-box panel" *ngIf="league.id === 14">
  <p><strong>Политика конфиденциальности о персональных данных Всероссийской лиги ВФСО &laquo;Трудовые резервы&raquo; по баскетболу</strong></p>
  <p>1. Общие положения</p>
  <p>Настоящая политика о конфиденциальности персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных посетителей сайта <a href="https://trbl.ru/">https://trbl.ru/</a>.</p>
  <p>Использование Пользователем ресурсов Сайта означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования ресурсов Сайта.</p>
  <p>Настоящая политика в отношении обработки персональных данных применяется ко всей информации, которую Оператор может получить о посетителях Сайта.</p>
  <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
  <p>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
  <p>2. Основные понятия, используемые в Политике</p>
  <p>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</p>
  <p>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных;</p>
  <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
  <p>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
  <p>Оператор &ndash; Общероссийская общественная организация &laquo;Всероссийское физкультурно-спортивное общество &laquo;Трудовые резервы&raquo;, Юридический адрес: 119019, г. Москва, Бульвар Гоголевский, д. 21, строение 1, эт.1, помещение III, комн.2ИНН 7704456650, ОГРН 1187700010190</p>
  <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
  <p>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
  <p>Политика - Политика о конфиденциальности персональных данных;</p>
  <p>Пользователь &ndash; любой посетитель Сайта;</p>
  <p>Сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://trbl.ru/">https://trbl.ru/</a></p>
  <p>3. Оператор может обрабатывать следующие персональные данные Пользователя</p>
  <ul>
    <li>Фамилия, имя, отчество;</li>
    <li>Электронный адрес;</li>
    <li>Номер телефона;</li>
    <li>Дата рождения;</li>
    <li>Адрес регистрации;</li>
    <li>Фотографическое изображение;</li>
  </ul>
  <p>Также на сайте может происходить сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;).</p>
  <p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
  <p>4. Цели обработки персональных данных</p>
  <p>Цель обработки персональных данных Пользователя &mdash;</p>
  <ul>
    <li>Идентификация Пользователя, зарегистрированного на Сайте;</li>
    <li>Предоставление Пользователю доступа к персонализированным ресурсам Сайта;</li>
    <li>Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя;</li>
    <li>Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем;</li>
    <li>Создание учетной записи, если Пользователь дал согласие либо самостоятельно зарегистрировал учетную запись;</li>
    <li>Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта;</li>
    <li>Осуществление рекламной деятельности с согласия Пользователя;</li>
    <li>Предоставление Пользователю уведомлений о новых продуктах и услугах, каталогов, прайсов, специальных предложений и иных сведений о различных событиях Оператора.</li>
  </ul>
  <p>Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@trurez.ru">info@trurez.ru</a> с пометкой &laquo;Отказ от уведомлений&raquo;.</p>
  <p>5. Правовые основания обработки персональных данных</p>
  <p>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
  <p>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
  <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
  <p>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
  <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с оказанием услуг Пользователю и с исполнением действующего законодательства.</p>
  <p>Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя/его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
  <p>Срок обработки персональных данных является неограниченным.</p>
  <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="https://trbl.ru/">https://trbl.ru/</a> с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
  <p>Уничтожение персональных данных будет произведено Оператором в срок, установленный Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
  <p>7. Заключительные положения</p>
  <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору на электронную почту <a href="mailto:info@trurez.ru">info@trurez.ru</a>.</p>
  <p>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
  <p>Пользователь соглашается с тем, что Сайт вправе передавать персональные данные соответствующего Пользователя третьим лицам, с целью выполнения своих обязательств перед Пользователем.</p>
  <p>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
  <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
  <p>Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
  <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://trbl.ru/">https://trbl.ru/</a>.</p>
</div>
<div class="agreement-box panel" *ngIf="league.id === 19">
  <p><strong>Политика конфиденциальности о персональных данных Всероссийской лиги ВФСО &laquo;Трудовые резервы&raquo; по волейболу</strong></p>
  <p>1. Общие положения</p>
  <p>Настоящая политика о конфиденциальности персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных посетителей сайта <a href="https://trvoll.ru">https://trvoll.ru</a> .</p>
  <p>Использование Пользователем ресурсов Сайта означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования ресурсов Сайта.</p>
  <p>Настоящая политика в отношении обработки персональных данных применяется ко всей информации, которую Оператор может получить о посетителях Сайта.</p>
  <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
  <p>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
  <p>2. Основные понятия, используемые в Политике</p>
  <p>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</p>
  <p>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных;</p>
  <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
  <p>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
  <p>Оператор &ndash; Общероссийская общественная организация &laquo;Всероссийское физкультурно-спортивное общество &laquo;Трудовые резервы&raquo;, Юридический адрес: 119019, г. Москва, Бульвар Гоголевский, д. 21, строение 1, эт.1, помещение III, комн.2ИНН 7704456650, ОГРН 1187700010190</p>
  <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
  <p>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
  <p>Политика - Политика о конфиденциальности персональных данных;</p>
  <p>Пользователь &ndash; любой посетитель Сайта;</p>
  <p>Сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://trvoll.ru">https://trvoll.ru</a> .</p>
  <p>3. Оператор может обрабатывать следующие персональные данные Пользователя</p>
  <ul>
    <li>Фамилия, имя, отчество;</li>
    <li>Электронный адрес;</li>
    <li>Номер телефона;</li>
    <li>Дата рождения;</li>
    <li>Адрес регистрации;</li>
    <li>Фотографическое изображение;</li>
  </ul>
  <p>Также на сайте может происходить сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;).</p>
  <p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
  <p>4. Цели обработки персональных данных</p>
  <p>Цель обработки персональных данных Пользователя &mdash;</p>
  <ul>
    <li>Идентификация Пользователя, зарегистрированного на Сайте;</li>
    <li>Предоставление Пользователю доступа к персонализированным ресурсам Сайта;</li>
    <li>Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя;</li>
    <li>Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем;</li>
    <li>Создание учетной записи, если Пользователь дал согласие либо самостоятельно зарегистрировал учетную запись;</li>
    <li>Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта;</li>
    <li>Осуществление рекламной деятельности с согласия Пользователя;</li>
    <li>Предоставление Пользователю уведомлений о новых продуктах и услугах, каталогов, прайсов, специальных предложений и иных сведений о различных событиях Оператора.</li>
  </ul>
  <p>Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@trurez.ru">info@trurez.ru</a> с пометкой &laquo;Отказ от уведомлений&raquo;.</p>
  <p>5. Правовые основания обработки персональных данных</p>
  <p>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
  <p>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
  <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
  <p>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
  <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с оказанием услуг Пользователю и с исполнением действующего законодательства.</p>
  <p>Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя/его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
  <p>Срок обработки персональных данных является неограниченным.</p>
  <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="https://trvoll.ru">https://trvoll.ru</a> с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
  <p>Уничтожение персональных данных будет произведено Оператором в срок, установленный Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
  <p>7. Заключительные положения</p>
  <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору на электронную почту <a href="mailto:info@trurez.ru">info@trurez.ru</a>.</p>
  <p>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
  <p>Пользователь соглашается с тем, что Сайт вправе передавать персональные данные соответствующего Пользователя третьим лицам, с целью выполнения своих обязательств перед Пользователем.</p>
  <p>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
  <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
  <p>Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
  <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу<a href="http://trvoll.ru">http://trvoll.ru</a> .</p>
</div>
<div class="agreement-box panel" *ngIf="league.id === 34">
  <p><strong>Политика конфиденциальности о персональных данных Всероссийской лиги ВФСО &laquo;Трудовые резервы&raquo; по футболу</strong></p>
  <p>1. Общие положения</p>
  <p>Настоящая политика о конфиденциальности персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных посетителей сайта <a href="https://trfl.ru/">https://trfl.ru/</a>.</p>
  <p>Использование Пользователем ресурсов Сайта означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования ресурсов Сайта.</p>
  <p>Настоящая политика в отношении обработки персональных данных применяется ко всей информации, которую Оператор может получить о посетителях Сайта.</p>
  <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
  <p>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
  <p>2. Основные понятия, используемые в Политике</p>
  <p>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</p>
  <p>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных;</p>
  <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
  <p>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
  <p>Оператор &ndash; Общероссийская общественная организация &laquo;Всероссийское физкультурно-спортивное общество &laquo;Трудовые резервы&raquo;, Юридический адрес: 119019, г. Москва, Бульвар Гоголевский, д. 21, строение 1, эт.1, помещение III, комн.2ИНН 7704456650, ОГРН 1187700010190</p>
  <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
  <p>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
  <p>Политика - Политика о конфиденциальности персональных данных;</p>
  <p>Пользователь &ndash; любой посетитель Сайта;</p>
  <p>Сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://trfl.ru/">https://trfl.ru/</a></p>
  <p>3. Оператор может обрабатывать следующие персональные данные Пользователя</p>
  <ul>
    <li>Фамилия, имя, отчество;</li>
    <li>Электронный адрес;</li>
    <li>Номер телефона;</li>
    <li>Дата рождения;</li>
    <li>Адрес регистрации;</li>
    <li>Фотографическое изображение;</li>
  </ul>
  <p>Также на сайте может происходить сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;).</p>
  <p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
  <p>4. Цели обработки персональных данных</p>
  <p>Цель обработки персональных данных Пользователя &mdash;</p>
  <ul>
    <li>Идентификация Пользователя, зарегистрированного на Сайте;</li>
    <li>Предоставление Пользователю доступа к персонализированным ресурсам Сайта;</li>
    <li>Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя;</li>
    <li>Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем;</li>
    <li>Создание учетной записи, если Пользователь дал согласие либо самостоятельно зарегистрировал учетную запись;</li>
    <li>Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта;</li>
  </ul>
  <ul>
    <li>Осуществление рекламной деятельности с согласия Пользователя;</li>
    <li>Предоставление Пользователю уведомлений о новых продуктах и услугах, каталогов, прайсов, специальных предложений и иных сведений о различных событиях Оператора.</li>
  </ul>
  <p>Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@trurez.ru">info@trurez.ru</a> с пометкой &laquo;Отказ от уведомлений&raquo;.</p>
  <p>5. Правовые основания обработки персональных данных</p>
  <p>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
  <p>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
  <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
  <p>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
  <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с оказанием услуг Пользователю и с исполнением действующего законодательства.</p>
  <p>Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя/его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
  <p>Срок обработки персональных данных является неограниченным.</p>
  <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="https://trfl.ru/">https://trfl.ru/</a> с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
  <p>Уничтожение персональных данных будет произведено Оператором в срок, установленный Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
  <p>7. Заключительные положения</p>
  <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору на электронную почту <a href="mailto:info@trurez.ru">info@trurez.ru</a>.</p>
  <p>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
  <p>Пользователь соглашается с тем, что Сайт вправе передавать персональные данные соответствующего Пользователя третьим лицам, с целью выполнения своих обязательств перед Пользователем.</p>
  <p>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
  <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
  <p>Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
  <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://trfl.ru/">https://trfl.ru/</a>.</p>
</div>
<div class="agreement-box panel" *ngIf="league.id === 39">
  <p><strong>Политика конфиденциальности о персональных данных Всероссийской лиги ВФСО &laquo;Трудовые резервы&raquo; по регби</strong></p>
  <p>1. Общие положения</p>
  <p>Настоящая политика о конфиденциальности персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo; и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных посетителей сайта <a href="https://trrl.ru/">https://trrl.ru/</a>.</p>
  <p>Использование Пользователем ресурсов Сайта означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки его персональных данных. В случае несогласия с этими условиями Пользователь должен воздержаться от использования ресурсов Сайта.</p>
  <p>Настоящая политика в отношении обработки персональных данных применяется ко всей информации, которую Оператор может получить о посетителях Сайта.</p>
  <p>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
  <p>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
  <p>2. Основные понятия, используемые в Политике</p>
  <p>Автоматизированная обработка персональных данных &ndash; обработка персональных данных с помощью средств вычислительной техники;</p>
  <p>Блокирование персональных данных &ndash; временное прекращение обработки персональных данных;</p>
  <p>Обезличивание персональных данных &mdash; действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
  <p>Обработка персональных данных &ndash; любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
  <p>Оператор &ndash; Общероссийская общественная организация &laquo;Всероссийское физкультурно-спортивное общество &laquo;Трудовые резервы&raquo;, Юридический адрес: 119019, г. Москва, Бульвар Гоголевский, д. 21, строение 1, эт.1, помещение III, комн.2ИНН 7704456650, ОГРН 1187700010190</p>
  <p>Предоставление персональных данных &ndash; действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
  <p>Распространение персональных данных &ndash; любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
  <p>Политика - Политика о конфиденциальности персональных данных;</p>
  <p>Пользователь &ndash; любой посетитель Сайта;</p>
  <p>Сайт &ndash; совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://trrl.ru/">https://trrl.ru/</a></p>
  <p>3. Оператор может обрабатывать следующие персональные данные Пользователя</p>
  <ul>
    <li>Фамилия, имя, отчество;</li>
    <li>Электронный адрес;</li>
    <li>Номер телефона;</li>
    <li>Дата рождения;</li>
    <li>Адрес регистрации;</li>
    <li>Фотографическое изображение;</li>
  </ul>
  <p>Также на сайте может происходить сбор и обработка обезличенных данных о посетителях (в т.ч. файлов &laquo;cookie&raquo;).</p>
  <p>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
  <p>4. Цели обработки персональных данных</p>
  <p>Цель обработки персональных данных Пользователя &mdash;</p>
  <ul>
    <li>Идентификация Пользователя, зарегистрированного на Сайте;</li>
    <li>Предоставление Пользователю доступа к персонализированным ресурсам Сайта;</li>
    <li>Установление с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя;</li>
    <li>Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем;</li>
    <li>Создание учетной записи, если Пользователь дал согласие либо самостоятельно зарегистрировал учетную запись;</li>
    <li>Предоставление Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта;</li>
  </ul>
  <ul>
    <li>Осуществление рекламной деятельности с согласия Пользователя;</li>
    <li>Предоставление Пользователю уведомлений о новых продуктах и услугах, каталогов, прайсов, специальных предложений и иных сведений о различных событиях Оператора.</li>
  </ul>
  <p>Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@trurez.ru">info@trurez.ru</a> с пометкой &laquo;Отказ от уведомлений&raquo;.</p>
  <p>5. Правовые основания обработки персональных данных</p>
  <p>Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
  <p>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</p>
  <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
  <p>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
  <p>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с оказанием услуг Пользователю и с исполнением действующего законодательства.</p>
  <p>Оператор осуществляет блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя/его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</p>
  <p>Срок обработки персональных данных является неограниченным.</p>
  <p>Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="https://trrl.ru/">https://trrl.ru/</a> с пометкой &laquo;Отзыв согласия на обработку персональных данных&raquo;.</p>
  <p>Уничтожение персональных данных будет произведено Оператором в срок, установленный Федеральным законом от 27.07.2006 г. № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
  <p>7. Заключительные положения</p>
  <p>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору на электронную почту <a href="mailto:info@trurez.ru">info@trurez.ru</a>.</p>
  <p>В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
  <p>Пользователь соглашается с тем, что Сайт вправе передавать персональные данные соответствующего Пользователя третьим лицам, с целью выполнения своих обязательств перед Пользователем.</p>
  <p>При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или разглашении персональных данных.</p>
  <p>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
  <p>Сайт не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.</p>
  <p>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://trrl.ru/">https://trrl.ru/</a>.</p>
</div>
