import * as RoutingMap from './routing-map';
import { environment } from '@env/environment';
import { ROUTE_TOURNAMENT_STAGES_RESULTS } from './routing-map';

export function getProfileRoute(): string[] {
  return [
    environment.portalHost,
    RoutingMap.ROUTE_PROFILE_ABSOLUTE
  ];
}

export function getProfileFormRoute(): string[] {
  return [
    ...getProfileRoute(),
    RoutingMap.ROUTE_PROFILE_FORM
  ];
}

export function getProfileSettingsRoute(): string[] {
  return [
    ...getProfileFormRoute(),
    RoutingMap.ROUTE_PROFILE_SETTINGS
  ];
}

export function getLeagueRoute() {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE];
}

export function getLeagueMediaRoute(): string[] {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE, RoutingMap.ROUTE_LEAGUE_MEDIA];
}

export function getLeagueMediaDetailRoute(mediaId?: number): string[] {
  return [...getLeagueMediaRoute(), mediaId ? mediaId.toString() : `:${RoutingMap.ROUTE_MEDIA_PARAM_KEY}`];
}

export function getLeagueTournamentsRoute(): string[] {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE, RoutingMap.ROUTE_LEAGUE_TOURNAMENTS];
}

export function getLeagueAboutRoute(): string[] {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE, RoutingMap.ROUTE_LEAGUE_ABOUT];
}

export function getLeagueContactsRoute(): string[] {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE, RoutingMap.ROUTE_LEAGUE_CONTACTS];
}

export function getLeagueDocumentsRoute(): string[] {
  return [RoutingMap.ROUTE_LEAGUE_ROOT_ABSOLUTE, RoutingMap.ROUTE_LEAGUE_DOCUMENTS];
}

export function getLeagueStandingsRoute(): string[] {
  return [
    `/${RoutingMap.ROUTE_LEAGUE_STANDINGS}`
  ];
}


export function getLeagueNewsListRoute(): string[] {
  return [
    `/${RoutingMap.ROUTE_NEWS_ROOT}`
  ];
}

export function getLeagueVideoListRoute(): string[] {
  return [
    `/${RoutingMap.ROUTE_VIDEO_ROOT}`
  ];
}

export function getLeagueVideoDetailRoute(videoId?: number): string[] {
  return [
    ...getLeagueVideoListRoute(),
    videoId ? videoId.toString() : `:${RoutingMap.VIDEO_DETAIL_KEY}`
  ];
}

export function getLeagueNewsDetailRoute(newsId?: number): string[] {
  return [
    ...getLeagueNewsListRoute(),
    newsId ? newsId.toString() : `:${RoutingMap.NEWS_DETAIL_KEY}`
  ];
}

export function getLeagueTeamsRoute(): string[] {
  return [
    ...getLeagueRoute(),
    RoutingMap.ROUTE_LEAGUE_TEAMS_ROUTE
  ];
}

export function getLeagueStatisticRoute(): string[] {
  return [
    ...getLeagueRoute(),
    RoutingMap.ROUTE_LEAGUE_STATISTIC_ROUTE
  ];
}

export function getTournamentRoute(alias?: string): string[] {
  return [RoutingMap.ROUTE_TOURNAMENT_ROOT_ABSOLUTE, alias || `:${RoutingMap.ROUTE_TOURNAMENT_PARAM}`];
}

export function getTournamentNewsRoute(tournamentAlias?: string): string[] {
  return [
    ...getTournamentRoute(tournamentAlias),
    RoutingMap.ROUTE_TOURNAMENT_NEWS_ROOT
  ];
}

export function getTournamentNewsDetailRoute(tournamentAlias?: string, newsId?: number): string[] {
  return [
    ...getTournamentNewsRoute(tournamentAlias),
    newsId ? newsId.toString() : `:${RoutingMap.NEWS_DETAIL_KEY}`
  ];
}

export function getGamesRoute(): string[] {
  return [
    `/${RoutingMap.ROUTE_LEAGUE_GAMES}`
  ];
}

export function getGameRoute(gameId?: number): string[] {
  return [
    `/${RoutingMap.ROUTE_TOURNAMENT_GAMES_ROOT}`,
    gameId ? gameId.toString() : `:${RoutingMap.ROUTE_TOURNAMENT_GAME_PARAM}`
  ];
}

export function getGameProtocolRoute(gameId?: number): string[] {
  return [
    ...getGameRoute(gameId),
    RoutingMap.ROUTE_GAME_PROTOCOL_ROOT
  ];
}

export function getGameTimelineRoute(gameId?: number): string[] {
  return [
    ...getGameRoute(gameId),
    RoutingMap.ROUTE_GAME_TIMELINE_ROOT
  ];
}

export function getGameMediaRoute(gameId?: number): string[] {
  return [
    ...getGameRoute(gameId),
    RoutingMap.ROUTE_GAME_MEDIA_ROOT
  ];
}

export function getGameMediaItemRoute(gameId?: number, mediaId?: number): string[] {
  return [
    ...getGameMediaRoute(gameId),
    mediaId ? mediaId.toString() : `:${RoutingMap.ROUTE_MEDIA_PARAM_KEY}`
  ];
}

export function getGameLiveRoute(gameId?: number): string[] {
  return [
    ...getGameRoute(gameId),
    RoutingMap.ROUTE_GAME_LIVE
  ];
}

export function getTeamPageRoute(teamId?: number): string[] {
  return [
    `/${RoutingMap.ROUTE_TOURNAMENT_TEAM_ROOT}`,
    teamId ? teamId.toString() : `:${RoutingMap.ROUTE_TOURNAMENT_TEAM_PARAM}`
  ];
}

export function getLeaguePlayerRoute(leaguePlayerId?: number): string[] {
  return [
    `/${RoutingMap.ROUTE_LEAGUE_PLAYER_ROOT}`,
    leaguePlayerId ? leaguePlayerId.toString() : `:${RoutingMap.ROUTE_LEAGUE_PLAYER_PARAM}`
  ];
}

export function getStagesRoute(tournamentAlias: string): string[] {
  return [
    ...getTournamentRoute(tournamentAlias),
    RoutingMap.ROUTE_TOURNAMENT_STAGES_ROOT
  ];
}

export function getStagesResultRoute(tournamentAlias: string): string[] {
  return [
    ...getTournamentRoute(tournamentAlias),
    RoutingMap.ROUTE_TOURNAMENT_STAGES_ROOT,
    RoutingMap.ROUTE_TOURNAMENT_STAGES_RESULTS,
  ];
}

export function getStageRoute(tournamentAlias?: string, stageId?: number): string[] {
  return [
    ...getTournamentRoute(tournamentAlias),
    RoutingMap.ROUTE_TOURNAMENT_STAGES_ROOT,
    stageId ? stageId.toString() : `:${RoutingMap.ROUTE_TOURNAMENT_STAGE_PARAM}`
  ];
}

export function getStageGroupsRoute(tournamentAlias?: string, stageId?: number): string[] {
  return [
    ...getStageRoute(tournamentAlias, stageId),
    RoutingMap.ROUTE_TOURNAMENT_STAGE_GROUPS
  ];
}

export function getStagePlayoffsRoute(tournamentAlias?: string, stageId?: number): string[] {
  return [
    ...getStageRoute(tournamentAlias, stageId),
    RoutingMap.ROUTE_TOURNAMENT_STAGE_PLAYOFF
  ];
}
