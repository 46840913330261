<div *ngIf="!feedbackSent" class="b-feedback-form">
  <div class="title">Обратная связь</div>
  <form [formGroup]="form">
    <div class="field">
      <mtg-dropdown [required]="true" label="Тема обращения" formControlName="theme">
        <mtg-dropdown-item *ngFor="let theme of FEEDBACK_TYPES" [value]="theme">
          {{ theme }}
        </mtg-dropdown-item>
      </mtg-dropdown>
    </div>
    <div class="field">
      <mtg-text-field type="textarea" [required]="true" formControlName="message" [label]="'Сообщение'"></mtg-text-field>
    </div>
    <div class="field" *ngIf="form.get('email').enabled">
      <mtg-text-field
        formControlName="email"
        label="Email"
        [required]="true"
      ></mtg-text-field>
    </div>
    <div class="field" *ngIf="form.get('captcha').enabled">
      <mtg-recaptcha
        formControlName="captcha"
        *ngIf="form.get('captcha').enabled"
      ></mtg-recaptcha>
    </div>
    <footer>
      <mtg-button (click)="sendFeedback()">
        Сохранить
      </mtg-button>
    </footer>
  </form>
</div>

<div class="succeed" *ngIf="feedbackSent">
  <div class="icon"><mtg-svg-icon icon="icons-done"></mtg-svg-icon></div>
  <div class="title">Обращение отправлено!</div>
  <div class="text-muted" *ngIf="!form.get('email').enabled">
    Ответ придёт на ваш E-mail, указанный в профиле
  </div>
  <a class="btn-link text-muted" (click)="close()">На главную</a>
</div>

