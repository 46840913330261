<div class="header">
  <div class="header-content">
    <div class="logo">
      <a [routerLink]="['/']">
        <img [src]="league.logo.path">
      </a>
    </div>
    <div class="title">
      <a [routerLink]="['/']">
        {{ league.name }}
      </a>
    </div>
    <div class="menu">
      <div class="item" *ngFor="let item of mainMenu">
        <a
          [routerLink]="item.routerLink"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: item.exact}"
        >{{ item.title }} <mtg-svg-icon *ngIf="item.subMenu && item.subMenu.length > 1" icon="icons-selector-down"></mtg-svg-icon></a>
        <div class="sub-menu" *ngIf="item.subMenu && item.subMenu.length > 1">
          <a
            *ngFor="let subItem of item.subMenu"
            [routerLink]="subItem.routerLink"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: subItem.exact}"
          >{{ subItem.title }}</a>
        </div>
      </div>
    </div>
    <a href="javascript:;" class="mobile-menu-toggle" (click)="toggleMobileMenu()"  [ngClass]="{opened: mobileMenuVisible}">
      <mtg-svg-icon icon="icons-burger" class="burger"></mtg-svg-icon>
      <mtg-svg-icon icon="icons-close" class="close"></mtg-svg-icon>
    </a>
  </div>
</div>

<div class="mobile-menu" *ngIf="mobileMenuVisible" [@slideLeft]>
  <a
    *ngFor="let item of mainMenu"
    (click)="hideMenu()"
    [routerLink]="item.routerLink"
    [routerLinkActiveOptions]="{exact: item.exact}"
    routerLinkActive="active"
    class="item"
  >{{ item.title }}</a>
  <div class="divider"></div>
  <a href="javascript:;" (click)="openAgreement()">Политика конфиденциальности</a>
  <a href="javascript:;" (click)="openFeedbackModal()">Обратная связь</a>
  <div class="copyright"></div>
</div>
