/* Auth routes */
export const ROUTE_AUTHENTICATION_ROOT = 'auth';
export const ROUTE_AUTHENTICATION_LOGIN = 'login';
export const ROUTE_AUTHENTICATION_LOGIN_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_LOGIN}`;
export const ROUTE_AUTHENTICATION_REGISTRATION = 'registration';
export const ROUTE_AUTHENTICATION_REGISTRATION_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_REGISTRATION}`;
export const ROUTE_AUTHENTICATION_CONFIRMATION = `confirmation`;
export const ROUTE_AUTHENTICATION_CONFIRMATION_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_CONFIRMATION}`;
export const ROUTE_AUTHENTICATION_INVITE_CONFIRM = `invite`;
export const ROUTE_AUTHENTICATION_REG_CONFIRM = 'confirm';
export const ROUTE_AUTHENTICATION_INVITE_CONFIRM_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_INVITE_CONFIRM}`;
export const ROUTE_AUTHENTICATION_RESET_PASSWORD = `reset_password`;
export const ROUTE_AUTHENTICATION_RESET_PASSWORD_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_RESET_PASSWORD}`;
export const ROUTE_AUTHENTICATION_CHANGE_PASSWORD = `change_password`;
export const ROUTE_AUTHENTICATION_CHANGE_PASSWORD_ABSOLUTE = `/${ROUTE_AUTHENTICATION_ROOT}/${ROUTE_AUTHENTICATION_CHANGE_PASSWORD}`;

/* MTG portal routes */
export const ROUTE_PROFILE = 'profile';
export const ROUTE_PROFILE_ABSOLUTE = `/${ROUTE_PROFILE}`;
export const ROUTE_PROFILE_FORM = `edit`;
export const ROUTE_PROFILE_SETTINGS = 'settings';

export const ROUTE_TEAM_ROOT = 'team';
export const ROUTE_TEAM_ROOT_ABSOLUTE = `/${ROUTE_TEAM_ROOT}`;

export const ROUTE_LEAGUE_ROOT_ABSOLUTE = `/`;
export const ROUTE_LEAGUE_ROOT = `league`;
export const ROUTE_LEAGUE_TOURNAMENTS = 'tournaments';
export const ROUTE_LEAGUE_MEDIA = 'media';
export const ROUTE_LEAGUE_ABOUT = 'about';
export const ROUTE_LEAGUE_CONTACTS = 'contacts';
export const ROUTE_NEWS_ROOT = 'news';
export const ROUTE_NEWS_DETAIL = ROUTE_NEWS_ROOT;
export const NEWS_DETAIL_KEY = 'newsId';
export const ROUTE_VIDEO_ROOT = 'video';
export const VIDEO_DETAIL_KEY = 'videoId';
export const ROUTE_LEAGUE_TEAMS_ROUTE = 'teams';
export const ROUTE_LEAGUE_STATISTIC_ROUTE = 'statistic';
export const ROUTE_LEAGUE_DOCUMENTS = 'documents';

/* Tournament routes */
export const ROUTE_TOURNAMENT_ROOT = 'tournament';
export const ROUTE_TOURNAMENT_ROOT_ABSOLUTE = `/${ROUTE_TOURNAMENT_ROOT}`;
export const ROUTE_TOURNAMENT_PARAM = 'alias';
export const ROUTE_TOURNAMENT_NEWS_ROOT = 'news';
export const ROUTE_TOURNAMENT_NEWS_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_NEWS_ROOT}`;
export const ROUTE_TOURNAMENT_JOIN = 'join';
export const ROUTE_TOURNAMENT_TABLE = 'table';
export const ROUTE_TOURNAMENT_GRID = 'grid';
export const ROUTE_TOURNAMENT_GRID_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GRID}`;
export const ROUTE_TOURNAMENT_GAMES_ROOT = 'games';
export const ROUTE_TOURNAMENT_GAME_PARAM = 'gameId';
export const ROUTE_GAME_STATISTIC_ROOT = 'statistic';
export const ROUTE_GAME_PROTOCOL_ROOT = 'protocol';
export const ROUTE_GAME_TIMELINE_ROOT = 'timeline';
export const ROUTE_GAME_MEDIA_ROOT = 'media';
export const ROUTE_GAME_LIVE = 'live';
export const ROUTE_TOURNAMENT_GAME_MEDIA_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GAMES_ROOT}/:gameId/${ROUTE_GAME_MEDIA_ROOT}`;
export const ROUTE_GAME_USERS = 'users';
export const ROUTE_TOURNAMENT_GAMES_LIST = 'list';
export const ROUTE_TOURNAMENT_GAMES_PLAYOFF = 'playoff';
export const ROUTE_TOURNAMENT_GAMES_PLAYOFF_ABSOLUTE = `${ROUTE_TOURNAMENT_ROOT_ABSOLUTE}/:alias/${ROUTE_TOURNAMENT_GAMES_PLAYOFF}`;
export const ROUTE_TOURNAMENT_STATISTIC = 'statistic';
export const ROUTE_TOURNAMENT_LEADERS = 'leaders';
export const ROUTE_TOURNAMENT_STATISTIC_PLAYERS = 'players';
export const ROUTE_TOURNAMENT_STATISTIC_GOALKEEPERS = 'goalkeepers';
export const ROUTE_TOURNAMENT_STATISTIC_TEAMS = 'teams';
export const ROUTE_TOURNAMENT_CONTACTS = 'contacts';
export const ROUTE_TOURNAMENT_MEDIA = 'media';
export const ROUTE_MEDIA_PARAM_KEY = 'mediaId';
export const ROUTE_TOURNAMENT_TEAM_ROOT = 'teams';
export const ROUTE_TOURNAMENT_TEAM_PARAM = 'teamId';
export const ROUTE_TOURNAMENT_USER_ROOT = 'players';
export const ROUTE_TOURNAMENT_USER_PARAM = 'leaguePlayerId';

export const ROUTE_LEAGUE_PLAYER_ROOT = 'player';
export const ROUTE_LEAGUE_PLAYER_PARAM = 'leaguePlayerId';

export const ROUTE_TOURNAMENT_FIBA_RESULTS = 'results';
export const ROUTE_TOURNAMENT_STAGES_RESULTS = 'results';
export const ROUTE_TOURNAMENT_STAGES_ROOT = 'stages';
export const ROUTE_TOURNAMENT_STAGE_PARAM = 'stageId';
export const ROUTE_TOURNAMENT_STAGE_RESULTS = 'results';
export const ROUTE_TOURNAMENT_STAGE_GROUPS = 'groups';
export const ROUTE_TOURNAMENT_STAGE_PLAYOFF = 'playoff';

export const ROUTE_LEAGUE_GAMES = 'games';

export const SEASON_QUERY_PARAM = 'season_id';
export const TOURNAMENT_QUERY_PARAM = 'tournament';
export const ROUND_QUERY_PARAM = 'round';
export const DIVISION_QUERY_PARAM = 'division';
export const STAGE_QUERY_PARAM = 'stage';

export const ROUTE_LEAGUE_STANDINGS  = 'standings';
