import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LayoutService } from './layout/layout.service';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, Location } from '@angular/common';
import { componentDestroyed, ConfigService, League, UntilDestroy } from '@mtgame/core';
import { environment } from '@env/environment';
import { AuthService } from '@mtgame/auth';
import { BehaviorSubject } from 'rxjs';
import { Metrika } from 'ng-yandex-metrika';
import { LeagueRootService } from '@core/services/league-root.service';
import { JoinComponent } from './join/join.component';
import { DialogService } from '@mtgame/ui';

@UntilDestroy
@Component({
  selector: 'mtg-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy{
  backgroundImage: string;
  isBrowser = isPlatformBrowser(this.platformId);
  isModalRoute = new BehaviorSubject({current: false, previous: false});
  previousRoute = null;
  league: League;

  constructor(
    private layoutService: LayoutService,
    private leagueRootService: LeagueRootService,
    private cdr: ChangeDetectorRef,
    private httpClient: HttpClient,
    private router: Router,
    private configService: ConfigService,
    private authService: AuthService,
    private dialogService: DialogService,
    private metrika: Metrika,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  ngOnInit(): void {
    this.configService.initialize(environment);
    if (this.isBrowser) {
      this.router.events
        .pipe(takeUntil(componentDestroyed(this)))
        .subscribe(event => {
          if (event instanceof ActivationStart) {
            this.isModalRoute.next({current: Boolean(event.snapshot.data.component), previous: this.isModalRoute.value.current});
          }
          if (event instanceof NavigationEnd) {
            const urlParse = event.url.split('?');
            if (!this.isModalRoute.value.previous && !this.isModalRoute.value.current && urlParse[0] !== this.previousRoute) {
              window.scrollTo(0, 0);
            }
            this.previousRoute = urlParse[0];
          }
        });
      let prevPath = this.location.path();
      this.router
        .events
        .pipe(filter(event => (event instanceof NavigationEnd)))
        .subscribe(() => {
          let newPath = this.location.path();
          if (newPath === '') {
            newPath = '/';
          }
          this.metrika.hit(newPath, {
            referer: prevPath,
          }, 0);
          this.metrika.hit(newPath, {
            referer: prevPath,
          }, 1);
          // window.ym(70909672, "hit", newPath, {referer: prevPath});
          prevPath = newPath;
        });
      this.authService.initialize();
    }
    this.layoutService.cover$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(backgroundImage => {
        this.backgroundImage = backgroundImage;
        // this.backgroundImage = '/assets/background-default.jpg';
        // this.backgroundImage = '/assets/cover-basket.jpg';
        this.cdr.detectChanges();
      });
    this.leagueRootService.league$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(league => {
        this.league = league;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
  }

  openJoinForm(): void {
    this.dialogService.open(JoinComponent, {
      isCloseButtonVisible: true
    });
  }
}
