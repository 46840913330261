import { Routes } from '@angular/router';
import * as RoutingMap from '@config/routing-map';
import { RootComponent } from './root.component';
import { RootResolver } from './root.resolver';
import { PingComponent } from './ping.component';
import { NotFoundComponent } from './not-found.component';
import { ROUTE_TOURNAMENT_GAME_PARAM } from '@config/routing-map';

export const ROUTES: Routes = [
  {
    path: 'ping',
    component: PingComponent
  },
  {
    path: 'invalid_domain',
    component: NotFoundComponent
  },
  {
    path: '',
    component: RootComponent,
    resolve: {
      league: RootResolver,
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./league/league.module').then(m => m.LeagueModule)
      },
      {
        path: `${RoutingMap.ROUTE_TOURNAMENT_ROOT}/:${RoutingMap.ROUTE_TOURNAMENT_PARAM}`,
        loadChildren: () => import('./tournament/tournament.module').then(m => m.TournamentModule)
      },
      {
        path: `${RoutingMap.ROUTE_TOURNAMENT_GAMES_ROOT}/:${ROUTE_TOURNAMENT_GAME_PARAM}`,
        loadChildren: () => import('./tournament-game/tournament-game.module').then(m => m.TournamentGameModule)
      },
      {
        path: `${RoutingMap.ROUTE_LEAGUE_PLAYER_ROOT}/:${RoutingMap.ROUTE_LEAGUE_PLAYER_PARAM}`,
        loadChildren: () => import('./league-player/league-player.module').then(m => m.LeaguePlayerModule)
      },
      {
        path: `${RoutingMap.ROUTE_TOURNAMENT_TEAM_ROOT}/:${RoutingMap.ROUTE_TOURNAMENT_TEAM_PARAM}`,
        loadChildren: () => import('./tournament-team/tournament-team.module').then(m => m.TournamentTeamModule),
      },
      {
        path: `${RoutingMap.ROUTE_LEAGUE_TEAMS_ROUTE}`,
        loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule)
      },
      {
        path: `${RoutingMap.ROUTE_LEAGUE_STATISTIC_ROUTE}`,
        loadChildren: () => import('./statistic/statistic.module').then(m => m.StatisticModule)
      },
      {
        path: RoutingMap.ROUTE_LEAGUE_GAMES,
        loadChildren: () => import('./games/games.module').then(m => m.GamesModule)
      },
      {
        path: RoutingMap.ROUTE_LEAGUE_STANDINGS,
        loadChildren: () => import('./standings/standings.module').then(m => m.StandingsModule),
      },
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      },
    ]
  },
];
