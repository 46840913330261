import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LeagueRootService } from '@core/services/league-root.service';

@Injectable()
export class LeagueTokenInterceptor implements HttpInterceptor {
  constructor(
    private leagueRootService: LeagueRootService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('x-app-name', 'hltr')
    });
    const league = this.leagueRootService.getLeagueModel();
    if (league) {
      const headers = req.headers.set('x-league-id', league.id.toString());
      req = req.clone({headers});
    }
    return next.handle(req);
  }
}
