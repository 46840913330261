<mtg-spinner *ngIf="!games"></mtg-spinner>
<mtg-data-slider
  *ngIf="games"
  [arrowInside]="true"
  [arrowVisible]="true"
  (scrolledToEnd)="loadNextPage()"
>
  <mtg-data-slider-item
    *ngFor="let game of games"
  >
    <mtg-game-headline-card
      [game]="game"
    ></mtg-game-headline-card>
  </mtg-data-slider-item>
  <mtg-data-slider-item *ngIf="loading">
    <div class="loading">
      <mtg-spinner></mtg-spinner>
    </div>
  </mtg-data-slider-item>
</mtg-data-slider>
