<div class="team-and-user-search" [ngClass]="{'active': !teamsAndUsers.isEmpty && typeahead.isVisible}">
  <mtg-text-field
    [errorVisible]="false"
    leadingIcon="icons-search"
    [formControl]="searchControl"
    [mtgTypeahead]="typeahead"
    [withClearButton]="true"
    placeholder="Игрок или команда"
  ></mtg-text-field>
  <mtg-typeahead
    class="custom-typeahead"
    #typeahead="mtgTypeahead"
    (change)="itemSelected($event)"
  >
    <ng-container *ngIf="teamsAndUsers">
      <div class="row-header" *ngIf="teamsAndUsers.teams && teamsAndUsers.teams.length > 0">Команды:</div>
      <mtg-typeahead-option class="row"
                            *ngFor="let item of teamsAndUsers.teams"
                            [value]="item"
      >
        <div class="item">
          <div class="image">
            <img [src]="item.logo.path" *ngIf="item.logo.path">
            <div [ngClass]="item.getEmptyLogoClass()" *ngIf="!item.logo.path"></div>
          </div>
          <div class="name">
            {{ item.name }}
          </div>
          <div class="arrow"><mtg-svg-icon icon="icons-corner-right"></mtg-svg-icon></div>
        </div>
      </mtg-typeahead-option>

      <div class="row-header" *ngIf="teamsAndUsers.leaguePlayers && teamsAndUsers.leaguePlayers.length > 0">Игроки:</div>
      <mtg-typeahead-option class="row"
                            *ngFor="let item of teamsAndUsers.leaguePlayers"
                            [value]="item"
      >
        <div class="item">
          <div class="image">
            <img [src]="item.photo.path" *ngIf="item.photo.path">
            <div class="empty-photo" *ngIf="!item.photo.path">{{ item.initials }}</div>
          </div>
          <div class="name">
            {{ item.fullName }}
          </div>
          <div class="arrow"><mtg-svg-icon icon="icons-corner-right"></mtg-svg-icon></div>
        </div>
      </mtg-typeahead-option>
    </ng-container>
  </mtg-typeahead>
</div>
