<a [routerLink]="gameRoute">
  <div class="date-card">
    <div class="time">
      {{ game.datetime | date: 'HH:mm' }}
    </div>
    <div class="date">
      {{ game.datetime | date: 'dd MMM' }}
    </div>
    <ng-container *ngIf="!game.team.sport.isHockey()">
      <div class="tour" *ngIf="!game.playoffStage">{{ getTourTitle(game.tournamentTour) }}</div>
      <div class="tour" *ngIf="game.playoffStage">
        <ng-container *ngIf="game.playoffStage === 'Матч за 3-е место'">3 место</ng-container>
        <ng-container *ngIf="game.playoffStage !== 'Матч за 3-е место'">{{ game.playoffStage }}</ng-container>
      </div>
    </ng-container>
    <div class="live" [ngClass]="{active: game.status === gameStatuses.in_progress}">
      <span class="dod"></span> LIVE
    </div>
  </div>
  <div class="game-card">
    <div class="content">
      <div class="team">
        <div class="logo">
          <mtg-svg-icon class="award-left" icon="custom-award-left" *ngIf="game.teamWinnerId === game.team.id"></mtg-svg-icon>
          <img [src]="game.team.logo.path" *ngIf="game.team.logo.path">
          <div [ngClass]="game.team.getEmptyLogoClass()" *ngIf="!game.team.logo.path"></div>
          <mtg-svg-icon class="award-right" icon="custom-award-right" *ngIf="game.teamWinnerId === game.team.id"></mtg-svg-icon>
        </div>
        <div class="name">{{ game.team.name }}</div>
      </div>
      <div class="center">
        <div class="period">{{ currentPeriod }}</div>
        <div class="score">
          <span>{{ game.teamScore }}</span>
          <span class="divider">:</span>
          <span>{{ game.competitorTeamScore }}</span>
        </div>
      </div>
      <div class="team">
        <div class="logo">
          <mtg-svg-icon class="award-left" icon="custom-award-left" *ngIf="game.teamWinnerId === game.competitorTeam.id"></mtg-svg-icon>
          <img [src]="game.competitorTeam.logo.path" *ngIf="game.competitorTeam.logo.path">
          <div [ngClass]="game.competitorTeam.getEmptyLogoClass()" *ngIf="!game.competitorTeam.logo.path"></div>
          <mtg-svg-icon class="award-right" icon="custom-award-right" *ngIf="game.teamWinnerId === game.competitorTeam.id"></mtg-svg-icon>
        </div>
        <div class="name">{{ game.competitorTeam.name }}</div>
      </div>
    </div>
    <div class="footer">
      <div class="court">
        <mtg-svg-icon icon="icons-pin"></mtg-svg-icon>
        {{ game.tournamentCourt.name }}
      </div>
      <div class="tournament" *ngIf="game.team.sport.isHockey()">
        <span class="tournament-logo">
          <img [src]="game.tournament.logo.path">
        </span>
        {{ tournamentName }}
      </div>
      <div class="tournament" *ngIf="!game.team.sport.isHockey()">
        <ng-container *ngIf="game.tournamentPlayoff.id">{{ game.tournamentPlayoff.name }}</ng-container>
        <ng-container *ngIf="!game.tournamentPlayoff.id">{{ game.tournamentGroup.name }}</ng-container>
      </div>
    </div>
  </div>
</a>
