export const environment = {
  production: true,
  env: 'prod',
  apiUrl: 'https://mtgame.ru',
  landingUrl: 'https://uralsib.trurez.ru',
  cdnHost: 'https://cdn.mtgame.ru',
  portalHost: 'https://mtgame.ru',
  centrifugoUrl: 'wss://ws2.mtgame.ru/connection/websocket',
  metrikaCounter: '66990874',
  recaptchaKey: '6LcpO3EaAAAAAAmFrvDaBrUo7Zx-NTQaxGfA35cx',
  sentryDsn: '',
  testDomain: ''
};
