<mtg-svg-icon-definitions></mtg-svg-icon-definitions>
<svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <symbol id="custom-award-left" viewBox="0 0 21 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3522 26.1727C10.3522 21.2653 12.4932 16.6468 16.2294 13.4796L17.3694 12.8213C18.844 11.97 19.8987 10.5954 20.3395 8.95067C20.7802 7.30585 20.5541 5.58804 19.7027 4.11343L17.6828 0.61496L15.0459 2.13724C12.0019 3.8947 10.9551 7.80111 12.7126 10.8451L13.2999 11.8623C12.8051 12.3352 12.3369 12.8305 11.8976 13.3469C11.3434 12.2523 10.4858 11.3383 9.41092 10.7177L5.91224 8.69777L4.38986 11.3346C3.53844 12.8092 3.31233 14.5271 3.75306 16.1718C4.1938 17.8166 5.24855 19.1912 6.72506 20.0437L7.94243 20.7444C7.768 21.359 7.62404 21.9837 7.50929 22.6161C6.48112 21.9457 5.28093 21.583 4.03982 21.583H0V24.6278C0 28.1427 2.85967 31.0024 6.37681 31.0024L7.78424 31.0005C7.94149 31.6263 8.12879 32.2403 8.34415 32.841C7.11696 32.7727 5.89568 33.0578 4.8211 33.6781L1.32253 35.6979L2.84491 38.3349C3.69622 39.8095 5.07084 40.8643 6.71567 41.305C7.26472 41.4521 7.82178 41.5249 8.37537 41.5249C9.48032 41.5249 10.571 41.2349 11.5547 40.6669L12.7829 39.9555C13.2296 40.4161 13.6984 40.8551 14.1876 41.2708C13.0896 41.8248 12.1726 42.684 11.5507 43.7614L9.53073 47.2602L12.1676 48.7824C13.1499 49.3496 14.2399 49.6393 15.3447 49.6393C15.8984 49.6393 16.4557 49.5666 17.0049 49.4193C18.628 48.9844 19.9871 47.9506 20.8419 46.5045V41.6396C14.7017 39.186 10.3522 33.178 10.3522 26.1727Z" />
    </symbol>

    <symbol id="custom-award-right" viewBox="0 0 21 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6253 31.0019C18.1403 31.0019 20.9999 28.1422 20.9999 24.6273V21.5825H16.96C15.7192 21.5825 14.5192 21.945 13.4911 22.6152C13.3764 21.9829 13.2325 21.3581 13.058 20.7436L14.2768 20.0421C15.7514 19.1908 16.8061 17.8162 17.2468 16.1715C17.6876 14.5266 17.4615 12.8088 16.6101 11.3342L15.0877 8.69729L11.589 10.7173C10.5143 11.3377 9.65687 12.2516 9.10266 13.3459C8.6634 12.8297 8.19535 12.3347 7.70072 11.8619L8.28805 10.8447C9.13946 9.37005 9.36558 7.65214 8.92495 6.00752C8.48421 4.3627 7.42946 2.98807 5.95475 2.13676L3.31803 0.61438L1.29796 4.11296C0.446544 5.58756 0.220428 7.30548 0.661059 8.9502C1.1018 10.5949 2.15655 11.9695 3.63115 12.8209L4.77112 13.4792C8.50731 16.6464 10.6483 21.2648 10.6483 26.1723C10.6483 33.1778 6.29856 39.1861 0.158203 41.6394V46.5039C1.01183 47.9492 2.37148 48.9837 3.99522 49.4188C4.54448 49.566 5.10165 49.6388 5.65544 49.6388C6.75997 49.6388 7.85026 49.3489 8.83246 48.7819L11.4694 47.2596L9.44942 43.7608C8.8275 42.6836 7.9107 41.8246 6.81292 41.2705C7.30217 40.8546 7.77096 40.4157 8.2177 39.955L9.44732 40.6675C10.4296 41.2347 11.5197 41.5243 12.6243 41.5243C13.178 41.5243 13.7354 41.4515 14.2845 41.3044C15.9292 40.8637 17.3038 39.8089 18.1552 38.3343L19.6776 35.6973L16.179 33.6775C15.1047 33.0572 13.8836 32.772 12.6567 32.8404C12.8721 32.2396 13.0593 31.6256 13.2165 30.9999L14.6253 31.0019Z" />
    </symbol>
  </defs>
</svg>
