<div class="player-menu" [ngClass]="{active: actionMenuVisible}">
  <div class="audio" [hidden]="!actionMenuVisible">
    <div class="content">
      <div class="mtg-audio">
        <mtg-audio #audioComponent
                   (keydown)="keydownEventHandler($event)"></mtg-audio>
      </div>
      <a href="{{ file.path }}" download="{{ file.path }}"><mtg-svg-icon icon="icons-cloud-download"></mtg-svg-icon></a>
    </div>
  </div>
</div>
