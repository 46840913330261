import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

export class MetaTag {
  name: string;
  value: string;
  isFacebook: boolean;

  constructor(name: string, value: string, isFacebook: boolean) {
    this.name = name;
    this.value = value;
    this.isFacebook = isFacebook;
  }
}

export interface SeoData {
  title?: string;
  description?: string;
  image?: string;
}

const urlMeta = 'og:url';
const titleMeta = 'og:title';
const descriptionMeta = 'og:description';
const imageMeta = 'og:image';
const secureImageMeta = 'og:image:secure_url';
const twitterTitleMeta = 'twitter:text:title';
const twitterImageMeta = 'twitter:image';

@Injectable({providedIn: 'root'})
export class SeoService {
  private data: SeoData = {};
  private defaultData: SeoData = {};

  constructor(
    private title: Title,
    @Inject(DOCUMENT) private doc: Document,
    private meta: Meta,
  ) {
  }

  setDefaultData(data: SeoData): void {
    this.defaultData = {
      ...this.defaultData,
      ...data
    };
    this.updateTitle();
    this.updateTags();
  }

  setData(data: SeoData): void {
    this.data = {
      ...this.data,
      ...data
    };
    this.updateTitle();
    this.updateTags();
  }

  reset(): void {
    this.data = {};
    this.updateTitle();
    this.updateTags();
  }

  private updateTitle(): void {
    this.title.setTitle(this.getTitle());
  }

  private updateTags(): void {
    const tags = [
      new MetaTag(urlMeta, `https://${this.doc.location.host}${this.doc.location.pathname}`, true),
      new MetaTag(titleMeta, this.getTitle(), true),
      new MetaTag(descriptionMeta, this.data.description || this.defaultData.description, true),
      new MetaTag(imageMeta, this.data.image || this.defaultData.image, true),
      new MetaTag(secureImageMeta, this.data.image || this.defaultData.image, true),
      new MetaTag(twitterTitleMeta, this.getTitle(), false),
      new MetaTag(twitterImageMeta, this.data.image || this.defaultData.image, false),
      new MetaTag('description', this.data.description || this.defaultData.description, false)
    ];
    tags.forEach(siteTag => {
      const tag = siteTag.isFacebook
        ? this.meta.getTag(`property='${siteTag.name}'`)
        : this.meta.getTag(`name='${siteTag.name}'`);
      if (siteTag.isFacebook) {
        this.meta.updateTag({ property: siteTag.name, content: siteTag.value || '' });
      } else {
        this.meta.updateTag({ name: siteTag.name, content: siteTag.value || '' });
      }
    });
  }

  private getTitle(): string {
    if (this.data.title && this.defaultData.title) {
      return `${this.data.title} - ${this.defaultData.title}`;
    } else {
      return this.data.title || this.defaultData.title || '';
    }
  }
}
