import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { UntilDestroy } from '@mtgame/core';

import { AudioComponent } from '@mtgame/ui';

@UntilDestroy
@Component({
  selector: 'mtg-hockey-playlist-runner',
  templateUrl: './hockey-playlist-runner.component.html',
  styleUrls: ['./hockey-playlist-runner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HockeyPlaylistRunnerComponent implements OnInit, OnDestroy, AfterViewInit {
  file = {name: 'Гимн', path: '/assets/gimn_trudovie.mp3'};
  actionMenuVisible = true;
  unsubscribe: () => void;

  @ViewChild('audioComponent', {read: AudioComponent})
  audioComponent: AudioComponent;

  constructor(
    private renderer2: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.unsubscribe = this.renderer2.listen('window', 'keydown', event => {
      if (event.code === 'Space' && this.audioComponent) {
        this.audioComponent.pauseAudio();
        return;
      }
    });
  }

  ngAfterViewInit(): void {
    this.togglePlaylistAudio();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    if (this.audioComponent) {
      this.audioComponent.pauseAudio();
    }
  }

  togglePlaylistAudio() {
    this.audioComponent.setMeta(this.file.name, this.file.path);
  }

  keydownEventHandler($event): void {
    if ($event.code === 'Space') {
      $event.preventDefault();
      return;
    }
  }
}
