import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '@mtgame/auth';
import { Store, User } from '@mtgame/core';
import { map, withLatestFrom } from 'rxjs/operators';

const STORE_LOGO_KEY = 'logo';
const STORE_TITLE_KEY = 'title';
const STORE_LEAGUE_MENU_KEY = 'league-menu';
const STORE_TOURNAMENT_MENU_KEY = 'tournament-menu';
const STORE_COVER_KEY = 'cover';
const STORE_DEFAULT_COVER_KEY = 'default-cover';
const STORE_USER_KEY = 'user';
const STORE_MAIN_ROUTE_KEY = 'main-route';

export interface MenuItem {
  title: string;
  routerLink: string[];
  subMenu?: MenuItem[];
  exact?: boolean;
}

@Injectable({providedIn: 'root'})
export class LayoutService {
  private store = new Store();

  get logo$(): Observable<string> {
    return this.store.get(STORE_LOGO_KEY);
  }

  get title$(): Observable<string> {
    return this.store.get(STORE_TITLE_KEY);
  }

  get leagueMenu$(): Observable<MenuItem[]> {
    return this.store.get(STORE_LEAGUE_MENU_KEY);
  }

  get tournamentMenu$(): Observable<MenuItem[]> {
    return this.store.get(STORE_TOURNAMENT_MENU_KEY);
  }

  get cover$(): Observable<string> {
    return combineLatest([
      this.store.get(STORE_COVER_KEY),
      this.store.get(STORE_DEFAULT_COVER_KEY),
    ]).pipe(map(([cover, defaultCover]) => cover || defaultCover));
  }

  get user$(): Observable<User> {
    return this.store.get(STORE_USER_KEY);
  }

  get mainRoute$(): Observable<string[]> {
    return this.store.get(STORE_MAIN_ROUTE_KEY);
  }

  constructor(
    private authService: AuthService,
  ) {
    this.authService.user$.subscribe(user => {
      this.store.save(STORE_USER_KEY, user);
    });
  }

  setTitle(title: string): void {
    this.store.save(STORE_TITLE_KEY, title);
  }

  setLogo(logo: string): void {
    this.store.save(STORE_LOGO_KEY, logo);
  }

  setLeagueMenu(menu: MenuItem[]): void {
    this.store.save(STORE_LEAGUE_MENU_KEY, menu);
  }

  setTournamentMenu(menu: MenuItem[]): void {
    this.store.save(STORE_TOURNAMENT_MENU_KEY, menu);
  }

  setCover(cover: string): void {
    this.store.save(STORE_COVER_KEY, cover);
  }

  setDefaultCover(cover: string): void {
    this.store.save(STORE_COVER_KEY, cover);
    this.store.save(STORE_DEFAULT_COVER_KEY, cover);
  }

  setMainRoute(route: string[]): void {
    this.store.save(STORE_MAIN_ROUTE_KEY, route);
  }
}
