import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { League } from '@mtgame/core';
import { LoaderService, ToastService } from '@mtgame/ui';
import { LeagueRootService } from '@core/services/league-root.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

@Injectable({providedIn: 'root'})
export class RootResolver implements Resolve<League> {
  constructor(
    private loaderService: LoaderService,
    private toastService: ToastService,
    private leagueRootService: LeagueRootService,
    private router: Router,
    @Inject(DOCUMENT) private document,
    @Optional() @Inject(REQUEST) protected request: Request,
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<League> {
    this.loaderService.show();
    try {
      return await this.leagueRootService.initialize(
        environment.testDomain ||
        (this.request && this.request.hostname) ||
        this.document.location.hostname
      );
    } catch (e) {
      if (e.status === 404) {
        this.router.navigate(['/invalid_domain'], {skipLocationChange: true, replaceUrl: false});
      } else {
        this.toastService.danger('Произошла ошибка');
      }
    } finally {
      this.loaderService.hide();
    }
  }
}
