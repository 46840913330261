import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameHeadlineCardComponent } from './components/game-headline-card/game-headline-card.component';
import { GamesHeadlineComponent } from './components/games-headline/games-headline.component';
import { DataSliderModule, ProgressModule, SvgIconModule } from '@mtgame/ui';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    ProgressModule,
    DataSliderModule,
    RouterModule
  ],
  exports: [
    GamesHeadlineComponent
  ],
  declarations: [
    GamesHeadlineComponent,
    GameHeadlineCardComponent
  ]
})
export class GamesHeadlineModule {}
