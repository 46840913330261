import { Component } from '@angular/core';
import { DialogService } from '@mtgame/ui';
import { AgreementComponent } from '../../agreement/agreement.component';
import { FeedbackComponent } from '../../feedback/feedback.component';
import { LeagueRootService } from '@core/services/league-root.service';

@Component({
  selector: 'mtg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year = (new Date()).getFullYear();
  league = this.leagueRootService.getLeagueModel();

  constructor(
    private leagueRootService: LeagueRootService,
    private dialogService: DialogService
  ) {}

  openAgreement() {
    this.dialogService.open(AgreementComponent, {
      isCloseButtonVisible: true
    });
  }

  openFeedbackModal() {
    this.dialogService.open(FeedbackComponent, {
      isCloseButtonVisible: true,
    });
  }
}
