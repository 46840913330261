import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import {
  ButtonModule,
  SvgIconModule,
  DialogModule,
  DropdownModule,
  UtilsModule,
  TextFieldModule,
  RecaptchaModule,
  RECAPTCHA_KEY, AudioModule, TypeaheadModule
} from '@mtgame/ui';
import { OverlayModule } from '@angular/cdk/overlay';
import { AgreementComponent } from './agreement/agreement.component';
import { AuthorizationModule, JwtAuthInterceptor } from '@mtgame/auth';
import { CONFIG_DATA, HttpCookieInterceptor } from '@mtgame/core';
import { RouterModule } from '@angular/router';
import { ROUTES } from './routes';
import { HeaderService } from './layout/header.service';
import { environment } from '@env/environment';
import { RootComponent } from './root.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MetrikaModule } from 'ng-yandex-metrika';
import * as Sentry from '@sentry/angular';
import { PingComponent } from './ping.component';
import { GamesHeadlineModule } from '../shared/games-headline/games-headline.module';
import { IconsComponent } from './icons.component';
import { CheckVersionComponent } from './check-version.component';
import { JoinComponent } from './join/join.component';
import { NotFoundComponent } from './not-found.component';
import { BrowserStateInterceptor } from './browser-state.interceptor';
import { HockeyPlaylistRunnerComponent } from './hockey-playlist-runner/hockey-playlist-runner.component';
import { TeamAndUserSearchComponent } from './team-and-user-search/team-and-user-search.component';
import { LeagueTokenInterceptor } from './league-token-interceptor';

registerLocaleData(localeRu);

const metrikaCounters = {
  'hltr.ru': 70909672,
  'trvoll.ru': 73858135,
  'trbl.ru': 73858105,
  'trfl.ru': 73858228,
  'trrl.ru': 79147786,
};
const myWin = typeof window !== 'undefined' ? window : null;


@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, { initialNavigation: 'enabledBlocking', relativeLinkResolution: 'legacy' }),
    LayoutModule,
    OverlayModule,
    HttpClientModule,
    TransferHttpCacheModule,
    UtilsModule,
    ButtonModule,
    SvgIconModule,
    DialogModule,
    AuthorizationModule,
    DropdownModule,
    ReactiveFormsModule,
    TextFieldModule,
    RecaptchaModule,
    AudioModule,
    MetrikaModule.forRoot(
      [
        {id: environment.metrikaCounter, webvisor: false},
        ...(myWin && myWin.location && metrikaCounters[myWin.location.hostname]
            ? [{id: metrikaCounters[myWin.location.hostname] , webvisor: true}]
            : []),
      ]
    ),
    GamesHeadlineModule,
    TypeaheadModule,
  ],
  declarations: [
    AppComponent,
    RootComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    AgreementComponent,
    FeedbackComponent,
    JoinComponent,
    PingComponent,
    IconsComponent,
    CheckVersionComponent,
    HockeyPlaylistRunnerComponent,
    TeamAndUserSearchComponent
  ],
  providers: [
    {provide: CONFIG_DATA, useValue: environment},
    {provide: LOCALE_ID, useValue: 'ru'},
    {provide: RECAPTCHA_KEY, useValue: environment.recaptchaKey},
    // BrowserStateInterceptor,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: BrowserStateInterceptor,
    //   multi: true
    // },
    // HttpCookieInterceptor,
    // HttpLogInterceptor,
    // JwtAuthInterceptor,
    // {provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: HttpCookieInterceptor, multi: true},
    LeagueTokenInterceptor,
    {provide: HTTP_INTERCEPTORS, useClass: LeagueTokenInterceptor, multi: true},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    // {provide: HTTP_INTERCEPTORS, useClass: HttpLogInterceptor, multi: true},
    HeaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
