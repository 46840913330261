import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CheckVersionService } from './check-version.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'mtg-check-version',
  templateUrl: './check-version.component.html',
  styleUrls: ['./check-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckVersionComponent implements OnInit {
  hasUpdates: boolean;
  closed: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private checkVersionService: CheckVersionService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkVersionService.initialize();
    }
    this.checkVersionService.hasUpdates$
      .subscribe(hasUpdates => {
        this.hasUpdates = hasUpdates;
        this.cdr.markForCheck();
      });
  }

  reload(): void {
    window.location.reload();
  }

  close(): void {
    this.closed = true;
  }

}
