import { Component } from '@angular/core';
import { LeagueRootService } from '@core/services/league-root.service';

@Component({
  selector: 'mtg-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss']
})
export class AgreementComponent {
  league = this.leagueRootService.getLeagueModel();

  constructor(
    private leagueRootService: LeagueRootService,
  ) {
  }
}
