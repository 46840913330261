<div class="footer">
  <div class="company">
    <img [src]="league.logo.path">
    {{ league.name }}
  </div>
  <ng-container *ngIf="league.sport.isHockey()">
    <a class="partner" href="https://minsport.gov.ru/" target="_blank"><img src="/assets/minsport-logo.png"/></a>
    <a class="partner" href="https://rostec.ru/" target="_blank"><img src="/assets/rosteh-new.png"/></a>
  </ng-container>
  <a class="partner" href="https://trurez.ru/" target="_blank"><img src="/assets/trurez-copyright.png"/></a>
  <a *ngIf="league.sport.isHockey()" class="partner" href="https://www.mos.ru/moskomsport/" target="_blank">
    <img alt="Москомсопрт" title="Москомспорт" src="assets/moscomsport-mini.png" />
  </a>

  <div class="social">
    <a [href]="league.youtubeLink" target="_blank" *ngIf="league.youtubeLink">
      <mtg-svg-icon icon="icons-youtube"></mtg-svg-icon>
    </a>
    <a [href]="league.vkLink" target="_blank" *ngIf="league.vkLink">
      <mtg-svg-icon icon="icons-vkontakte"></mtg-svg-icon>
    </a>
    <!--<a [href]="league.fbLink" target="_blank" *ngIf="league.fbLink">
      <mtg-svg-icon icon="icons-facebook"></mtg-svg-icon>
    </a>-->
    <a [href]="league.telegramLink" target="_blank" *ngIf="league.telegramLink">
      <mtg-svg-icon icon="icons-telegram"></mtg-svg-icon>
    </a>
    <!--<a [href]="league.instaLink" target="_blank" *ngIf="league.instaLink">
      <mtg-svg-icon icon="icons-instagram"></mtg-svg-icon>
    </a>-->
    <a [href]="league.tiktokLink" target="_blank" *ngIf="league.tiktokLink">
      <mtg-svg-icon icon="icons-tiktok"></mtg-svg-icon>
    </a>
  </div>
  <div class="links">
    <a href="javascript:;" (click)="openAgreement()">Политика конфиденциальности</a>
    <a href="javascript:;" (click)="openFeedbackModal()">Обратная связь</a>
  </div>
</div>
