export interface LinkFavicon {
  rel: string;
  sizes?: string;
  href: string;
  type?: string;
  color?: string;
}

export function getIconsData(folder: string): LinkFavicon[] {
  return [
    {rel: 'apple-touch-icon', sizes: '180x180', href: `/assets/favicon/${folder}apple-touch-icon.png`},
    {rel: 'icon', type: 'image/png', sizes: '32x32', href: `/assets/favicon/${folder}favicon-32x32.png`},
    {rel: 'icon', type: 'image/png', sizes: '16x16', href: `/assets/favicon/${folder}favicon-16x16.png`},
    {rel: 'shortcut icon', href: `/assets/favicon/${folder}favicon.ico`},
    {rel: 'manifest', href: `/assets/favicon/${folder}site.webmanifest`},
    {rel: 'mask-icon', href: `/assets/favicon/${folder}safari-pinned-tab.svg`, color: '#ff0000'}
  ];
}

export function changeFavicons(document: Document, leagueId: number): void {
  let folder = '';
  if (leagueId === 2) {
    folder = 'abl/';
  } else if (leagueId === 3) {
    folder = 'sbl/';
  } else if (leagueId === 12) {
    folder = 'trurez/';
  } else if (leagueId === 19) {
    folder = 'trvoll/';
  } else if (leagueId === 14) {
    folder = 'trbl/';
  } else if (leagueId === 34) {
    folder = 'trfl/';
  } else if (leagueId === 37) {
    folder = 'trrl_new/';
  } else if (leagueId === 106) {
    folder = 'trhl/';
  }
  const data = getIconsData(folder);
  for (const item of data) {
    let link = document.querySelector(`link[rel="${item.rel}"]${item.type ? `[type="${item.type}"]` : ''}${item.sizes ? `[sizes="${item.sizes}"]` : ''}`);
    if (!link) {
      link = document.createElement('link');
      for (const key of Object.keys(item)) {
        link.setAttribute(key, item[key]);
      }
      document.head.appendChild(link);
    } else {
      link.setAttribute('href', item.href);
    }
  }
}
